import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAllBlog, GetBlogById, GetBlogByTitle } from "./BlogAction";

const initialState = {
  blog: null,
  blogbytitle:null,
  blogs: [],
  size: null,
  randomBlog:[],
};

export const getallblogs = createAsyncThunk(
  "BLOGS_LIST",
  async ({ page, per_page }) => {
    const response = await GetAllBlog(page, per_page);
    return response.data;
  }
);

export const getblogsbyid = createAsyncThunk("Blog By id", async (id) => {
  const response = await GetBlogById(id);
  return response.data;
});

export const getblogsbytitle = createAsyncThunk("Blog By title", async (title) => {
  const response = await GetBlogByTitle(title);
  return response.data;
});

export const getRandomBlogs= createAsyncThunk("Random blogs", async({page,per_page})=>{
  const response = await GetAllBlog(page,per_page);
  return response.data
  
})
export const blogslice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    resetBlogs(state) {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getallblogs.fulfilled, (state, action) => {
      state.blogs = action?.payload?.data;
      state.size = action?.payload?.totalItems;
    });

    builder.addCase(getblogsbyid.fulfilled, (state, action) => {
      state.blog = action?.payload?.data;
    });
    builder.addCase(getblogsbytitle.fulfilled, (state, action) => {
      state.blogbytitle = action?.payload?.data;
    });
    builder.addCase(getRandomBlogs.fulfilled, (state, action) => {
      state.randomBlog = action?.payload?.data;
    });
  },
});

export default blogslice.reducer;
export const { resetBlogs } = blogslice.actions;

export const bloglist = (state) => state.blog.blogs;
export const blogdetails = (state) => state.blog.blog;
export const blogdetailsbytitle = (state) => state.blog.blogbytitle;
export const blogsize = (state) => state.blog.size;
export const randomBlog = (state) => state?.blog.randomBlog;
