import { axiosInstance } from "../../config/Axios";
import { dataAxios } from "../../config/dataAxios";
const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const SUBSCRIPTIONSLIST = API_URL + "/subscription_plan";
export const USER_SUBSCRIPTION = API_URL + "/subscription";
export function GetAllSubscription() {
  return axiosInstance
    .get(SUBSCRIPTIONSLIST,{
      params: {
        autoactivate:"1"
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}


export function GetUserSubscriptions() {
   return dataAxios
    .get(USER_SUBSCRIPTION)
    .then((res) => {
       return res;
    })
    .catch((err) => {
      return err;
    });
}

export function ChooseSubscription(subscriptionData) {
  return dataAxios
    .post(USER_SUBSCRIPTION, subscriptionData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
