import axios from "axios";
export const axiosInstance = axios.create(
    {
        baseURL: process.env.NEXT_PUBLIC_API_URL, 
        headers: {
            // Authorization: `Bearer ${token}`,
          },
    }
);

axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });