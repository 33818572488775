import { axiosInstance } from "../../config/Axios";
const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const BLOGLIST = API_URL + "/blog";
export const BLOGBYTITLE = API_URL + "/blogs";
export function GetAllBlog(page, per_page) {
  
  return axiosInstance

  .get(BLOGLIST, {
    params: {
      per_page: per_page,
      page: page,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function GetBlogById(id) {
  return axiosInstance
    .get(BLOGLIST + "/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function GetBlogByTitle(title) {
  return axiosInstance
    .get(BLOGBYTITLE + "/" + title)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

